import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { FC, useMemo } from 'react';
import { Flex, Text } from 'components/Layout';
import { format, isValid, parse } from 'date-fns';

import { AdminAppRoutes } from 'AdminApp';
import { Ghost } from 'components/Loading';
import { IconButton } from 'components/Buttons';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import config from 'config/app';
import { flatten } from 'lodash';
import { getRouteWithParams } from 'utils/router';
import { replaceLabelWithContext } from 'components/Forms/Utils/ReplaceLabel';
import styled from 'styled-components';
import { theme } from 'theme';
import { uiSchema } from 'container/client/SingleForm/SingleForm';
import { useHistory } from 'react-router';
import { weight } from 'theme/styles/size';
import { Icon } from 'components/Images';
import { FormFull, ProcedureFull } from '../../../../types/resources';
import { useTranslation } from 'react-i18next';
import { formatFormTitle } from '../../../../utils/format';

interface FormsAnswersProps {
  form: FormFull;
  procedure: ProcedureFull;
}

interface Input {
  answer: string;
  id: string;
  step: number;
  key: string;
}

interface FormInputProps {
  input: Input;
  label: string;
  redirectToEdit: () => void;
}

type FormAnswersContext = Record<string, string>;

interface AnswerProps {
  answer: {
    id: string;
    step: number;
    value: string | Record<string, string>[] | null;
    updated_at: string | null;
    key: string;
  };
  label?: string | null;
  formAnswersContext: FormAnswersContext;
  redirectToEdit: () => void;
}

const Wrapper = styled.div`
  width: 100%;
`;

const Step = styled.div`
  padding: ${theme.spacing.space40} 0;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.gray6};

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
    border: 0;
  }
`;

const InputWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: ${theme.spacing.space32};
  width: 100%;
`;

const InputValuesWrapper = styled.div`
  grid-column: span 2;
  border: 1px solid ${theme.colors.green1};
  padding: ${theme.spacing.space24};
  border-radius: ${theme.spacing.space16};
`;

const CustomInput = styled(InputText)`
  width: 100%;
  height: ${theme.spacing.space56};
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  &::placeholder {
    font-weight: ${weight.medium};
    color: ${theme.colors.black};
  }
`;

const IconContainer = styled.div`
  margin-left: ${theme.spacing.space16};
  margin-top: 50px;
  cursor: pointer;
  display: flex;
`;

const FormInput: FC<FormInputProps> = ({ input, label, redirectToEdit }) => {
  const { answer, key, id } = input;

  const formatAnswer = (answer: string) => {
    const isDate =
      !Number(answer) && isValid(parse(answer, 'yyyy-MM-dd', new Date()));
    if (isDate) return format(new Date(answer), config.dateFormat);
    return answer;
  };

  const handleInputClick = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Flex direction={{ xs: 'row' }}>
      <Flex id={`${id}_${key}`} width="100%">
        <CustomInput
          label={label}
          placeholder={formatAnswer(answer)}
          disabled
          cursor="copy"
          icon={{ name: 'Edit' }}
          onClick={redirectToEdit}
          labelFontStyle="body1"
        />
      </Flex>
      <Tooltip
        anchorSelect={`#${id}_${key}`}
        place="bottom"
        content={formatAnswer(answer)}
      />
      <IconContainer onClick={() => handleInputClick(formatAnswer(answer))}>
        <Icon name="copy" />
      </IconContainer>
    </Flex>
  );
};

const Answer: FC<AnswerProps> = ({
  answer,
  label,
  formAnswersContext,
  redirectToEdit,
}) => {
  const input: Input = {
    answer:
      answer.value && typeof answer.value === 'string'
        ? formAnswersContext[answer.value] ||
          replaceLabelWithContext(answer.value, formAnswersContext)
        : '',
    id: answer.id,
    step: answer.step,
    key: answer.key,
  };

  return (
    <FormInput
      input={input}
      label={label || input.key}
      redirectToEdit={redirectToEdit}
    />
  );
};

const FormsAnswers: FC<FormsAnswersProps> = ({ form, procedure }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const uiOrder = useMemo(() => {
    if (!form) return [];
    return flatten(
      uiSchema[form.form_template_id as keyof typeof uiSchema].map(
        (el) => el['ui:order'],
      ),
    );
  }, [form]);

  const sortedAnswersByStep = useMemo(() => {
    if (!form.answers || uiOrder.length === 0) return [];

    const arrToSort = [...form.answers];
    const sortedAnswer = arrToSort.sort(function (a, b) {
      return uiOrder.indexOf(a.key) - uiOrder.indexOf(b.key);
    });

    return sortedAnswer.reduce<Record<number, typeof sortedAnswer>>(
      (acc, answer) => ({
        ...acc,
        [answer.step]: [...(acc[answer.step] || []), answer],
      }),
      {},
    );
  }, [form, uiOrder]);

  // please make sure this is sync with API form schemas
  const formAnswersContext = {
    c1: procedure.spouse1?.full_name,
    c2: procedure.spouse2?.full_name,
    1: '1 mois',
    3: '3 mois',
    6: '6 mois',
    12: '12 mois',
    0: 'Pas de départ',
    alt: 'En garde alternée',
    enf: 'Enfant majeur',
    capital: 'Capital payé en une fois',
    capital_echelon: 'Capital payé en plusieurs fois',
    rente_viag: 'Rente mensuelle',
    both: 'Remboursé par moitié par les deux conjoints',
    loc: 'Une location',
    commun_indiv: 'Un bien commun',
    propre_c1: `Un bien propre de ${procedure.spouse1?.full_name} (acheté seul)`,
    propre_c2: `Un bien propre de ${procedure.spouse2?.full_name} (acheté seul)`,
    indiv_vendu: 'Un bien acheté par les époux sous contrat de mariage',
    indiv_preco: 'Un bien acheté par les époux avant le mariage',
    dead: "Il n'y a plus de domicile conjugal",
    fra: 'France',
    abroad: 'Etranger',
    initial: 'Initial (signé avant le mariage)',
    modif: 'Modification du régime matrimonial (signé après le mariage)',
  } as FormAnswersContext;

  const redirectToEdit = (step: string) => {
    const pathname = getRouteWithParams(AdminAppRoutes.CLIENT_FORM, {
      id: form.id || '',
      opportunityId: procedure.id,
    });

    history.push({ pathname, search: `?step=${step}` });
  };

  return (
    <Wrapper>
      <Text
        fontStyle="heading1"
        content={formatFormTitle(form)}
        marginBottom={{ xs: 'space48' }}
        weight="bold"
      />
      {!form ? (
        <InputWrapper>
          {[...Array(20)].map((_, index) => (
            <Ghost
              key={`loading_form_${index}`}
              width="100%"
              height={56}
              shape="rect"
              rx={16}
            />
          ))}
        </InputWrapper>
      ) : (
        <Flex direction={{ xs: 'column' }}>
          {Object.entries(sortedAnswersByStep).map(([step, answers]) => (
            <Step key={`step-${step}`}>
              <Flex
                alignItems="center"
                marginBottom={{ xs: 'space32' }}
                onClick={() => redirectToEdit(step)}
                cursor="pointer"
              >
                <Text
                  fontStyle="heading5"
                  content={`Étape ${Number(step) + 1}`}
                  weight="bold"
                  marginRight={{ xs: 'space8' }}
                  color={theme.colors.green1}
                />
                <IconButton
                  iconName="ArrowRight"
                  color={theme.colors.green1}
                  backgroundColor={theme.colors.green2}
                  rounded
                  size="xs"
                />
              </Flex>
              <InputWrapper>
                {answers.map((answer) => {
                  const label = t(`yoProcess.labels.${answer.key}`);

                  if (typeof answer.value === 'string') {
                    return (
                      <Answer
                        answer={answer}
                        label={label}
                        redirectToEdit={() => redirectToEdit(step)}
                        formAnswersContext={formAnswersContext}
                        key={answer.id}
                      />
                    );
                  } else if (Array.isArray(answer.value)) {
                    return answer.value.map(
                      (value: Record<string, string>, index) => {
                        const valuesKeys = Object.keys(value);
                        return (
                          <InputValuesWrapper
                            key={`${answer.id}_index_${index}`}
                          >
                            {label && (
                              <Text
                                content={`${label} ${index + 1}`}
                                fontStyle="heading3"
                                marginBottom={{ xs: 'space24' }}
                              />
                            )}
                            <InputWrapper>
                              {valuesKeys.map((key) => {
                                const valueLabel = t(
                                  `yoProcess.labels.${answer.key}_${key}`,
                                );

                                const answerParam = {
                                  ...answer,
                                  value: value[key],
                                  key,
                                };
                                return (
                                  <Answer
                                    key={`${key}_${index}`}
                                    label={valueLabel}
                                    answer={answerParam}
                                    redirectToEdit={() => redirectToEdit(step)}
                                    formAnswersContext={formAnswersContext}
                                  />
                                );
                              })}
                            </InputWrapper>
                          </InputValuesWrapper>
                        );
                      },
                    );
                  }
                })}
              </InputWrapper>
            </Step>
          ))}
        </Flex>
      )}
    </Wrapper>
  );
};

export default FormsAnswers;
