import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItem } from 'components/List';
import { useViewport } from 'hooks/useViewport';
import { ButtonProps } from 'components/Buttons/Button';
import { FormStatus, FormTemplateId } from '../../../types/resources';

interface FormItemProps {
  type: FormTemplateId;
  title: string;
  status: FormStatus;
  onClick?: () => void;
  label: string;
  count: number;
  isAdmin?: boolean;
  hideButton?: boolean;
}

const FormItem: FC<FormItemProps> = ({
  type,
  title,
  status,
  onClick,
  label,
  count,
  isAdmin,
  hideButton = false,
}) => {
  const { t } = useTranslation();
  const { width } = useViewport();
  const isMobile = width <= 991;
  const toComplete = status === FormStatus.TO_COMPLETE;
  const toReview = status === FormStatus.IN_REVIEW;

  const getIconByType = (type: FormTemplateId) => {
    switch (type) {
      case FormTemplateId.PROPERTIES:
        return 'Home';
      case FormTemplateId.ADULT_CHILDREN:
        return 'People';
      case FormTemplateId.AGREEMENT_YOUNG_CHILDREN:
        return 'People';
      case FormTemplateId.YOUNG_CHILDREN:
        return 'People';
      case FormTemplateId.AGREEMENT_ADULT_CHILDREN:
        return 'People';
      case FormTemplateId.MARITAL_HOME:
        return 'Home';
      default:
        return 'Wallet';
    }
  };

  const iconName = getIconByType(type);
  const buttons: ButtonProps[] = [
    {
      onClick,
      primary: (isAdmin && toReview) || (!isAdmin && toComplete),
      content: isMobile ? '' : label,
      iconLeft: isMobile ? { name: 'EditSquare' } : undefined,
    },
  ];

  return (
    <ListItem
      title={title}
      iconName={iconName}
      primary={toComplete}
      chip={
        count > 0
          ? isMobile
            ? `${count}`
            : t('forms_opportunity.comments_count', { count })
          : ''
      }
      buttons={!hideButton ? buttons : undefined}
    />
  );
};

export default FormItem;
